@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

#root {
  height: 100%;
  display: flex;
  flex: 1;
}

.aspect-ratio-overlay {
  aspect-ratio: 1.7776;
}

img[data-sizes="auto"] {
  display: block;
}

.mediabox {
  position: relative;
}

.mediabox-img.ls-blur-up-is-loading,
.mediabox-img.lazyload:not([src]) {
  visibility: hidden;
}

.ls-blur-up-img,
.mediabox-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;

  font-family: "blur-up: auto", "object-fit: contain";

  object-fit: contain;
}

.ls-blur-up-img {
  filter: opacity(100%);
  opacity: 1;
  transition: opacity 1000ms, filter 1500ms;
}

.ls-blur-up-img.ls-inview.ls-original-loaded {
  opacity: 0;
  filter: opacity(0%);
}


p {
  font-family: 'Noto Serif'
}

.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  position: relative;
}

.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}